.vds-modal-content{
    width: 300px;
}
.radio-field div{
    display: flex;
}
.radio-field #active-group__subtitle, .radio-field #bypass-lane-group__subtitle, 
.radio-field #osc-lane-group__subtitle, .radio-field #cold-lane-group__subtitle{
    margin-top: 16px;
    padding-right: 10px;
}
.vds-modal .eds-modal__content{
    min-height: 500px !important;
    margin-top: 30px;
    padding: 20px;
}
.vds-modal .eds-modal{
    min-height: 600px;
    padding: 0px !important;
    overflow-y: scroll;
    display: block !important;
}
.vds-modal .eds-modal__header{
    font-size: 20px;
    text-align: left;
    position: absolute;
    gap: 245px;
    z-index: 59;
    background: white;
    border-bottom: 1px solid #3333330d;
    padding: 5px 13px;
    border-radius: 5px 5px 0 0;
}
.vds-modal .eds-label{
    font-size: 14px !important;
}