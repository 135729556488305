.file-operation{
    justify-content: center;
}
.file-operation div{
    padding-top: 1.3rem;
    /* border-bottom: 1px solid #cacacb; */
}
.allocation-text{
    color: #333;
    padding: 0rem 0rem 1rem 0rem;
}
.carrier-allocation-box{
    background-color: #fff;
    padding-left: 0;
    border-radius: 8px;
    overflow-y: scroll;
    overflow-x: scroll;
    min-height: 400px;
    scrollbar-width: thin;
    scrollbar-color: #0f0f0fbf #80808033;
}
.carrier-allocation-table{
    /* overflow-x: scroll; */
    margin-left: 6rem;
    /* margin-right: 4rem; */
    /* overflow-y: visible; */
    padding: 0;
}
.carrier-allocation-table table{
    border-radius: 0;
    width: 100%;
}
.cellPopover{
   box-shadow: -2px 4px 10px rgb(1 1 1 / 32%)
}
.cell{
    padding: 0.3rem !important;
    font-size: 12px;
    color: #707072;
    cursor: pointer;
}
.header-cell{
    padding: 0.3rem !important;
    font-size: 14px;
    color: #333;
    font-weight: bold;
    word-break: break-word;
    min-width: 6rem !important;
}
.date-header-cell{
    padding: 0.3rem !important;
    position: absolute;
    min-width: 6rem !important;
    width: 6rem;
    left: 0rem;
    top: auto;
    background: #fff;
    color: #333;
    font-size: 14px;
    font-weight: bold;
    /* margin-top: -1px; */
}
.action-cell{
    padding: 0.3rem !important;
    position: absolute;
    min-width: 4rem !important;
    width: 4rem;
    right: 0rem;
    top: auto;
    background: #fff;
    color: #333;
    font-size: 14px;
    text-align: center;
    /* margin-top: -1px; */
}
.date-cell{
    padding: 0.3rem !important;
    position: absolute;
    min-width: 6rem !important;
    width: 6rem;
    left: 0rem;
    top: auto;
    background: #fff;
    white-space: nowrap;
    font-size: 12px;
    color: #707072;
    /* margin-top: -1px; */
}
.edit-cell{
    padding: 0.3rem !important;
    position: absolute;
    min-width: 4rem !important;
    width: 4rem;
    right: 0rem;
    top: auto;
    background: #fff;
    white-space: nowrap;
    /* margin-top: -1px; */
}
.edit-cell div{
    justify-content: center;
}
.edit-cell svg{
    height: 16px;
}
.page-no{
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
}
.input-box .eds-text-field{
    margin-top: 0 !important;
}
.edit-cell-input-box .eds-text-field__input{
    padding: 1px 3px !important;
    font-size: 12px;
}
.edit-cell-input-box .eds-text-field{
    margin-top: 0 !important;
}
.disabledCell{
    color: #bcbcbd;
}
/*.css-t3ipsp-control{
    min-height: 30px !important;
    padding: 0px 16px !important;
    font-size: 14px !important;
}
.css-13cymwt-control{
    min-height: 30px !important;
    padding: 4px 16px !important;
    font-size: 14px !important;
}
.eds-select__indicators{
    padding: 0 !important;
}
.input-box .css-qbdosj-Input {
    grid-area: 3/1/1/1 !important;
}
.input-box .eds-select .eds-select__value-container--is-multi{
    margin-right: 0 !important;
    margin-left: 0 !important;
}
.input-box .css-1xc3v61-indicatorContainer{
    padding: 0px 8px 8px 8px;
}
.input-box .eds-select__indicator-separator{
    margin-top: 0;
}*/
.eds-text-field__input{
    padding: 6px 16px;
    font-size: 14px;
}
.flexBtn .eds-button--medium{
    padding: 5px 24px;
}
.paginationBtn .eds-button--medium{
    padding: 5px 24px;
}
.paginationBtn .eds-button--disabled{
    background-color: #c0bebe;
    color: #000000;
}
.more-filter{
    background: #f8f8f7;
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px #5252528a;
    margin-top: 1rem;
}
.more-filter-text{
    margin-top: 1rem;
    color: #1a0dab;
    cursor: pointer;
    text-align: left;
}
.more-filter-text:hover{
    text-decoration: underline;
}
.delete-filter{
    display: flex;
    cursor: pointer;
    width: 30px;
}

/* Scroll Bar CSS */
/* width */
.carrier-allocation-table::-webkit-scrollbar, .carrier-allocation-box::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  /* Track */
  .carrier-allocation-table::-webkit-scrollbar-track, .carrier-allocation-box::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  .carrier-allocation-table::-webkit-scrollbar-thumb, .carrier-allocation-box::-webkit-scrollbar-thumb{
    background: rgba(15, 15, 15, 0.75); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .carrier-allocation-table::-webkit-scrollbar-thumb:hover, .carrier-allocation-box::-webkit-scrollbar-thumb:hover{
    background:rgba(35, 35, 35, 0.75);  
  }
  .flexBtn{
    text-align: end;
    /* padding-top: 1.2rem; */
  }
  .hideFilter:hover{
    text-decoration: underline;
    cursor: pointer;
  }
  .table-spinner{
    justify-content: center;
    align-items: center;
    text-align: center;
    display: inline-flex;
    min-height: 369px;
  }
  .modal-spinner{
    justify-content: center;
    align-items: center;
    text-align: center;
    display: inline-flex;
    position: absolute;
    background-color: rgba(97, 95, 95, 0.2);;
    min-height: 270px;
    width: 100%;
  }
  .upload-modal .eds-modal{
    padding: 0;
    min-height: 220px;
    display: block !important;
  }
  .upload-modal .eds-modal__header, .upload-modal .upload-modal-content div, .upload-modal .eds-modal__footer{
    padding: 10px;
  }
  .upload-modal .eds-modal__content{
    display: inline-block;
}
.upload-btn .eds-button--disabled{
    background-color: #c0bebe;
    color: #000000;
}
.cancel-btn .eds-button--disabled{
    color: var(--eds-color-black);
    background-color: rgba(0,0,0,0);
    border: 1.5px solid var(--eds-color-grey-2);
}
.input-box .eds-select .eds-select__control {
    padding: 0px 0px 0px 15px;
    min-height: 40px;
    border-color: inherit;
    cursor: pointer;
}
