.edit-modal .eds-text-field{
    margin-top: 0 !important;
}
.info-modal .eds-modal{
    max-height: none !important;
    width: 570px !important;
    min-height: 300px;
}
.edit-text{
    color: #333;
    text-align: left;
}
.info-modal .eds-modal__content{
    margin-top: 5px;
}
.info-modal .eds-modal{
    padding: 10px !important;
    display: block !important;
    overflow-y: scroll;
}
.info-modal .eds-tab{
    padding-bottom: 1px;
}
.info-table .eds-table-heading{
    min-width: 90px !important;
}
.info-table .eds-table{
    width: 100%;
    text-align: left;
}
.info-table .eds-table-cell, .info-table .eds-table-heading{
    padding: 0 12px !important;
    font-size: 14px;
}
.info-table .eds-table-heading:first-of-type, .info-table .eds-table-cell:first-of-type {
    padding-left: 2px !important;
}
.info-table .eds-table-heading:last-of-type, .info-table .eds-table-cell:last-of-type {
    padding-right: 2px !important;
}