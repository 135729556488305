.eds-snackbar{
    min-width: 600px !important;
    max-width: max-content !important;
}

.eds-snack__body{
    max-height: 100px;
    overflow-y: scroll;
    padding: 0px 8px;
}

.eds-snack{
    min-width: 564px !important;
}

.eds-snack__body::-webkit-scrollbar, .eds-snack__body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
.eds-snack__body::-webkit-scrollbar-track, .eds-snack__body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}

/* Handle */
.eds-snack__body::-webkit-scrollbar-thumb, .eds-snack__body::-webkit-scrollbar-thumb{
    background: rgba(15, 15, 15, 0.75); 
    border-radius: 10px;
}

/* Handle on hover */
.eds-snack__body::-webkit-scrollbar-thumb:hover, .eds-snack__body::-webkit-scrollbar-thumb:hover{
    background:rgba(35, 35, 35, 0.75);  
}

.snackButtons{
    cursor: pointer;
}