.changeLog-text{
    color: #333;
    padding: 0rem 0rem 1rem 0rem;
}
.changeLogBtn .eds-button--medium{
    padding: 5px 24px;
}
.changeLogBtn{
    margin-top: 20px;
    text-align: center;
}
.upload-changeLog-modal .eds-modal{
    padding: 0;
    display: block !important;
    min-height: 220px;
}
.upload-changeLog-modal .eds-modal__header, .upload-changeLog-modal .upload-changeLog-modal-content div, .upload-changeLog-modal .eds-modal__footer{
    padding: 10px;
}
.upload-changeLog-modal .eds-modal__content{
    display: inline-block;
}
.upload-changeLog-modal .eds-text-field{
    margin-top: 0 !important;
}
.upload-changeLog-btn .eds-button--disabled{
    background-color: #c0bebe;
    color: #000000;
}
.cancel-changeLog-btn .eds-button--disabled{
    color: var(--eds-color-black);
    background-color: rgba(0,0,0,0);
    border: 1.5px solid var(--eds-color-grey-2);
}
.changeLog-modal-spinner{
    justify-content: center;
    align-items: center;
    text-align: center;
    display: inline-flex;
    position: absolute;
    background-color: rgba(97, 95, 95, 0.2);;
    min-height: 270px;
    width: 100%;
}
.changeLog-input-box .eds-text-field{
    margin-top: 0 !important;
}
.edit-cell-changeLog-input-box .eds-text-field__input{
    padding: 1px 3px !important;
    font-size: 12px;
}
.edit-cell-changeLog-input-box .eds-text-field{
    margin-top: 0 !important;
}
.changeLog-input-box .eds-select .eds-select__control {
    padding: 0px 0px 0px 15px;
    min-height: 40px;
    border-color: inherit;
    cursor: pointer;
}
.changeLog-box{
    background-color: #fff;
    padding-left: 0;
    border-radius: 8px;
    overflow-x: scroll;
    overflow-y: scroll;
    min-height: 400px;
}
.changeLog-table{
    margin-left: 6rem;
    padding: 0;
}
.changeLog-table table{
    border-radius: 0;
    width: 100%;
}
.changeLog-table::-webkit-scrollbar, .changeLog-box::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  /* Track */
  .changeLog-table::-webkit-scrollbar-track, .changeLog-box::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  .changeLog-table::-webkit-scrollbar-thumb, .changeLog-box::-webkit-scrollbar-thumb{
    background: rgba(15, 15, 15, 0.75); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .changeLog-table::-webkit-scrollbar-thumb:hover, .changeLog-box::-webkit-scrollbar-thumb:hover{
    background:rgba(35, 35, 35, 0.75);  
  }
  .changeLog-table-spinner{
    justify-content: center;
    align-items: center;
    text-align: center;
    display: inline-flex;
    min-height: 369px;
  }
  .changeLog-date-cell{
    padding: 0.3rem !important;
    position: absolute;
    min-width: 6rem !important;
    width: 6rem;
    left: 0rem;
    top: auto;
    background: #fff;
    white-space: nowrap;
    font-size: 12px;
    color: #707072;
    /* margin-top: -1px; */
}
.changeLog-date-header-cell{
    padding: 0.3rem !important;
    position: absolute;
    min-width: 6rem !important;
    width: 6rem;
    left: 0rem;
    top: auto;
    background: #fff;
    color: #333;
    font-size: 14px;
    font-weight: bold;
    /* margin-top: -1px; */
}
.changeLog-header-cell{
    padding: 0.3rem !important;
    font-size: 14px;
    color: #333;
    font-weight: bold;
    word-break: break-word;
    min-width: 6rem !important;
}
.changeLog-cell{
    padding: 0.3rem !important;
    font-size: 12px;
    color: #707072;
    cursor: pointer;
}
.changeLog-action-cell{
    padding: 0 !important;
    font-size: 12px;
    color: #707072;
    cursor: pointer;
}
.changeLog-action-cell .eds-icon__wrapper--s {
    padding: 3px !important;
}
.edit-modal .eds-modal{
    width: 60%;
    overflow: hidden;
}
.eds-modal__top-fade, .eds-modal__bottom-fade{
    background: none !important;
}
.edit-modal .eds-scrim .eds-modal__content>div{
    overflow: hidden !important;
}
.changeLog-paginationBtn .eds-button--medium{
    padding: 5px 24px;
}
.changeLog-paginationBtn .eds-button--disabled{
    background-color: #c0bebe;
    color: #000000;
}