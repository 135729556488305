.shipVia-text{
    color: #333;
    padding: 0rem 0rem 1rem 0rem;
}
.shipViaBtn .eds-button--medium{
    padding: 5px 24px;
}
.shipViaBtn{
    text-align: end;
}
.upload-shipVia-modal .eds-modal{
    padding: 0;
    display: block !important;
    min-height: 220px;
}
.upload-shipVia-modal .eds-modal__header, .upload-shipVia-modal .upload-shipVia-modal-content div, .upload-shipVia-modal .eds-modal__footer{
    padding: 10px;
}
.upload-shipVia-modal .eds-modal__content{
    display: inline-block;
}
.upload-shipVia-modal .eds-text-field{
    margin-top: 0 !important;
}
.upload-shipVia-btn .eds-button--disabled{
    background-color: #c0bebe;
    color: #000000;
}
.cancel-shipVia-btn .eds-button--disabled{
    color: var(--eds-color-black);
    background-color: rgba(0,0,0,0);
    border: 1.5px solid var(--eds-color-grey-2);
}
.shipVia-modal-spinner{
    justify-content: center;
    align-items: center;
    text-align: center;
    display: inline-flex;
    position: absolute;
    background-color: rgba(97, 95, 95, 0.2);;
    min-height: 270px;
    width: 100%;
}
.shipVia-input-box .eds-text-field{
    margin-top: 0 !important;
}
.edit-cell-shipVia-input-box .eds-text-field__input{
    padding: 1px 3px !important;
    font-size: 12px;
}
.edit-cell-shipVia-input-box .eds-text-field{
    margin-top: 0 !important;
}
.shipVia-input-box .eds-select .eds-select__control {
    padding: 0px 0px 0px 15px;
    min-height: 40px;
    border-color: inherit;
    cursor: pointer;
}
.shipVia-box{
    background-color: #fff;
    padding-left: 0;
    border-radius: 8px;
    overflow-y: scroll;
    overflow-x: scroll;
    min-height: 400px;
    scrollbar-width: thin;
    scrollbar-color: #0f0f0fbf #80808033;
}
.shipVia-table{
    padding: 0;
}
.shipVia-table table{
    border-radius: 0;
    width: 100%;
}
.shipVia-table::-webkit-scrollbar, .shipVia-box::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  /* Track */
  .shipVia-table::-webkit-scrollbar-track, .shipVia-box::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  .shipVia-table::-webkit-scrollbar-thumb, .shipVia-box::-webkit-scrollbar-thumb{
    background: rgba(15, 15, 15, 0.75); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .shipVia-table::-webkit-scrollbar-thumb:hover, .shipVia-box::-webkit-scrollbar-thumb:hover{
    background:rgba(35, 35, 35, 0.75);  
  }
  .shipVia-table-spinner{
    justify-content: center;
    align-items: center;
    text-align: center;
    display: inline-flex;
    min-height: 369px;
  }
  .shipVia-date-cell{
    padding: 0.3rem !important;
    position: absolute;
    min-width: 6rem !important;
    width: 6rem;
    left: 0rem;
    top: auto;
    background: #fff;
    white-space: nowrap;
    font-size: 12px;
    color: #707072;
    /* margin-top: -1px; */
}
.shipVia-date-header-cell{
    padding: 0.3rem !important;
    position: absolute;
    min-width: 6rem !important;
    width: 6rem;
    left: 0rem;
    top: auto;
    background: #fff;
    color: #333;
    font-size: 14px;
    font-weight: bold;
    /* margin-top: -1px; */
}
.shipVia-header-cell{
    padding: 0.3rem !important;
    font-size: 14px;
    color: #333;
    font-weight: bold;
    word-break: break-word;
    min-width: 6rem !important;
}
.shipVia-cell{
    padding: 0.3rem !important;
    font-size: 12px;
    color: #707072;
    cursor: pointer;
    text-align: left;
}
.shipVia-action-cell{
    padding: 0 !important;
    font-size: 12px;
    color: #707072;
    cursor: pointer;
}
.shipVia-action-cell .eds-icon__wrapper--s {
    padding: 3px !important;
}
.edit-modal .eds-modal{
    width: 55%;
}