.vds-text {
    color: #333;
    padding: 0rem 0rem 1rem 0rem;
}

.vdsBtn {
    text-align: end;
}

.vdsBtn .eds-button--medium {
    padding: 5px 24px;
}

.vds-input-box .eds-text-field {
    margin-top: 0 !important;
}

.edit-cell-vds-input-box .eds-text-field__input {
    padding: 1px 3px !important;
    font-size: 12px;
}

.edit-cell-vds-input-box .eds-text-field {
    margin-top: 0 !important;
}

.vds-input-box .eds-select .eds-select__control {
    padding: 0px 0px 0px 15px;
    min-height: 40px;
    border-color: inherit;
    cursor: pointer;
}


.vds-box {
    background-color: #fff;
    padding-left: 0;
    border-radius: 8px;
    overflow-y: scroll;
    overflow-x: scroll;
    min-height: 400px;
    scrollbar-width: thin;
    scrollbar-color: #0f0f0fbf #80808033;
}


.disabledCell {
    color: #bcbcbd;
}

.vds-table {
    /* padding: 0; */
    /* scrollbar-width: thin; */
    /* scrollbar-color: #0f0f0fbf #80808033; */
    /* overflow-x: scroll; */
    /* margin-left: 6rem; */
    /* margin-right: 4rem; */
    /* overflow-y: scroll; */
    padding: 0;
}

.vds-table-spinner {
    justify-content: center;
    align-items: center;
    text-align: center;
    display: inline-flex;
    min-height: 369px;
}


.vds-table table {
    border-radius: 0;
    width: 100%;
}

.vds-table::-webkit-scrollbar,
.vds-box::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
.vds-table::-webkit-scrollbar-track,
.vds-box::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.vds-table::-webkit-scrollbar-thumb,
.vds-box::-webkit-scrollbar-thumb {
    background: rgba(15, 15, 15, 0.75);
    border-radius: 10px;
}

.vds-header-cell {
    padding: 0.3rem !important;
    font-size: 14px;
    color: #333;
    font-weight: bold;
    word-break: break-word;
    min-width: 6rem !important;
}

.vds-cell {
    padding: 0.3rem !important;
    font-size: 12px;
    color: #707072;
    cursor: pointer;
}





.vds-action-cell {
    padding: 0 !important;
    font-size: 12px;
    color: #707072;
    cursor: pointer;
}

.vds-action-cell .eds-icon__wrapper--s {
    padding: 3px !important;
}


@media (min-width: 992px) {
    .col-lg-5ths {
        flex: 0 0 20%;
        max-width: 20%;
    }
}


@media (min-width: 1200px) {
    .col-xl-5ths {
        flex: 0 0 20%;
        max-width: 20%;
    }
}


/* .vds-header-cell{
    padding: 0.3rem !important;
    font-size: 14px;
    color: #333;
    font-weight: bold;
    word-break: break-word;
    min-width: 6rem !important;
} */
/* .vds-cell{
    padding: 0.3rem !important;
    font-size: 12px;
    color: #707072;
    cursor: pointer;
    text-align: left;
} */


.upload-vds-modal .eds-modal {
    padding: 0;
    display: block !important;
    min-height: 220px;
}

.upload-vds-modal .eds-modal__header,
.upload-vds-modal .upload-vds-modal-content div,
.upload-vds-modal .eds-modal__footer {
    padding: 10px;
}

.upload-vds-modal .eds-modal__content {
    display: inline-block;
}

.upload-vds-modal .eds-text-field {
    margin-top: 0 !important;
}


.upload-vds-btn .eds-button--disabled {
    background-color: #c0bebe;
    color: #000000;
}



.cancel-vds-btn .eds-button--disabled {
    color: var(--eds-color-black);
    background-color: rgba(0, 0, 0, 0);
    border: 1.5px solid var(--eds-color-grey-2);
}


.cellPopover {
    box-shadow: -2px 4px 10px rgb(1 1 1 / 32%)
}

.eds-popover {
    padding: 20px;
}