.video-container{
    min-height: 300px;
    padding: 1% 0% 2% 0%;
    background-color: #F5F5F5;
}

.btnLink .eds-button--medium{
    padding: 5px 24px;
}

.btnLink{
    padding: 5px 24px;
    background-color: #F5F5F5;
}